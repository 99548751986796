import { CameraIcon } from "@heroicons/react/24/outline";
import Avatar, { AvatarInterface } from "../common/avatars/avatar";
import { Link } from "@inertiajs/react";
import { unsplashCrop } from "@/utils/index";
import { FormattedMessage } from "react-intl";

type ArticleType = {
    slug: string;
    heroUrl?: string;
    title: string;
    user: AvatarInterface["user"];
    publishedAt: string;
    content: string;
    topics: { label: string }[];
    account_name: string;
};

export default function ArticleCard({ article }: { article: ArticleType }) {
    return (
        <li className="col-span-1 rounded-lg bg-white overflow-hidden focus-within:outline focus-within:outline-2 focus-within:outline-black outline-offset-2">
            <Link
                href={route("show-news", article.slug)}
                className="focus:outline-none"
            >
                <div className="relative flex flex-col items-left">
                    {article.heroUrl ? (
                        <img
                            src={unsplashCrop(article.heroUrl)}
                            alt={article.title}
                            className="rounded-lg w-full h-96 object-cover"
                        />
                    ) : (
                        <div className="rounded-lg w-full h-96 object-cover bg-rose-100 text-rose-400 flex items-center justify-center">
                            <CameraIcon className="size-48" />
                        </div>
                    )}
                    <div className="relative flex mt-4 items-center">
                        <Avatar user={article.user} />
                        <p className="text-sm ml-2">
                            <span className="ml-0.5 text-rose-600 font-semibold">
                                {article.account_name}
                            </span>
                            <span className="ml-0.5 text-gray-500">
                                &bull; {article.publishedAt}
                            </span>
                        </p>
                    </div>
                    <div className="flex flex-col items-left mt-4 mb-4">
                        <span className="text-xl font-medium">
                            {article.title}
                        </span>
                        <span
                            className="mt-2 mb-2 block text-base font-light text-gray-700 text-left leading-6"
                            dangerouslySetInnerHTML={{
                                __html:
                                    article.content
                                        .replace(/(<([^>]+)>)/gi, "")
                                        .slice(0, 125) +
                                    (article.content.length > 125 ? "..." : ""),
                            }}
                        />
                    </div>
                    <div className="flex flex-wrap gap-2">
                        {article.topics.map((item) => (
                            <span
                                className="inline-flex items-center rounded-full bg-gray-100 px-3 py-1 text-sm font-medium text-gray-600"
                                key={item.label}
                            >
                                <FormattedMessage id={item.label} />
                            </span>
                        ))}
                    </div>
                </div>
            </Link>
        </li>
    );
}
